import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import ElementUI from "element-ui";
import { createPinia, PiniaVuePlugin } from "pinia";
import { createPersistedState } from "pinia-persistedstate-plugin";
import 'windi.css'

import "@/styles/iconfont/iconfont.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss";

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(router);
// 注册pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(createPersistedState());
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
