import Vue from "vue";
import VueRouter from "vue-router";

// 注册路由插件
Vue.use(VueRouter);

//
const routes = [
  {
    path: "/",
    component: () => import("@/components/layout/Index.vue"),
    hidden: true,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/pages/home/Index.vue"),
        meta: {
          title: "艺云教育",
        },
      },
      {
        path: "hardware",
        component: () => import("@/views/pages/productionInfo/Index.vue"),
        meta: {
          title: "艺云教育-硬件详情",
        },
        props: {
          type: "1",
        },
      },
      {
        path: "software",
        component: () => import("@/views/pages/productionInfo/Index.vue"),
        meta: {
          title: "艺云教育-软件详情",
        },
        props: {
          type: '2',
        },
      },
      {
        //     companyIntro
        path: "companyInfo",
        component: () => import("@/views/pages/aboutUs/Index.vue"),
        meta: {
          title: "艺云教育-公司介绍",
        },
        props: {
          type: '1',
        },
      },
      {
        path: "companyActive",
        component: () => import("@/views/pages/aboutUs/Index.vue"),
        meta: {
          title: "艺云教育-公司动态",
        },
        props: {
          type: "2",
        },
      },
      {
        path: "aboutUs",
        component: () => import("@/views/pages/aboutUs/Index.vue"),
        meta: {
          title: "艺云教育-关于我们",
        },
        props: {
          type: "3",
        },
      },
      {
        path: "article",
        component: () => import("@/views/pages/aboutUs/Article.vue"),
        meta: {
          title: "艺云教育-动态详情",
        },
      },
      {
        path: "serverDownload",
        component: () => import("@/views/pages/serverDownload/Index.vue"),
        meta: {
          title: "艺云教育-服务承诺",
        },
      },
      {
        path: "appDownLoad",
        component: () => import("@/views/pages/softwareDownload/Index.vue"),
        meta: {
          title: "艺云教育-软件下载",
        },
      },
      {
        path: "serviceNetwork",
        component: () => import("@/views/pages/serviceNetwork/Index.vue"),
        meta: {
          title: "艺云教育-服务网点",
        },
      },
      // {
      //   path: "ops",
      //   component: () => import("@/views/pages/ops/Index.vue"),
      //   meta: {
      //     title: "艺云教育-OPS",
      //   },
      // },
      {
        path: "productTutorial",
        component: () => import("@/views/pages/productTutorial/Index.vue"),
        meta: {
          title: "艺云教育-产品教程",
        },
      },
      {
        path: "productTutorialPlayer",
        name: "productTutorialPlayer",
        component: () => import("@/views/pages/productTutorial/Player.vue"),
        meta: {
          title: "艺云教育-产品教程",
        },
      },
      {
        path: "useManage",
        name: "useManage",
        component: () => import("@/views/pages/useManage/Index.vue"),
        meta: {
          title: "管理端",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
