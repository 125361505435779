/** pinia/index.js */ 
import { createPinia } from 'pinia';

const pinia = createPinia();

export default pinia;

export { default as app } from './modules/app';


// 组件外使用
// import { settings } from '@/pinia';
// const settingsStore = settings();
// settingsStore.setTitle(to.meta.title)

// 组件内使用
// import { mapState, mapActions } from 'pinia';
// import { app, user, settings } from '@/pinia';
// ...mapActions(user, ['logOut']),
// ...mapState(app, ['sidebar', 'device']),
