import { defineStore } from "./defineStore";
import axios from "axios";

const getList = async () => {
  try {
    const protocolType = document.location.protocol;
    let res = await axios.get(
        `${protocolType}//school-operation.boeart.cn/open/application/list`
    );
    return res.data.rows;
  } catch (error) {
    console.log("获取软件列表失败");
  }
};
export default defineStore({
  id: "app",
  // 开启数据缓存
  persist: {
    enabled: true,
  },
  state: () => {
    return {
      appList: [],
    };
  },
  getters: {
    getAppList() {
      return this.appList;
    },
  },
  actions: {
    async setAppList() {
      try {
        let res = await getList();
        this.appList = res;
      } catch (error) {}
    },
  },
});
